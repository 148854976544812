<template>
  <div>
    <a-modal
      v-model="visible"
      :title="action === 'Create' ? 'Tambah ' : 'Edit ' + ' Role Permission'"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
      :forceRender="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-danger" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit()"
        >
          Simpan
        </a-button>
      </template>
      <div class="row">
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Keterangan</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-input class="" v-model="input.keterangan"></a-input>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
// import Cleave from 'vue-cleave-component'

export default {
  components: {
    // cleave: Cleave,
  },
  data() {
    return {
      action: 'Create',
      editdata: [],
      input: {
        keterangan: '',
      },
      editshiftmode: false,
      datatable: [],
      dataSource: [],
      datapermissions: [],
      dataroles: [],
      dataroletype: [],
      visible: false,
      loading: false,
      datenow: '',
    }
  },
  created() {
    this.datenow = lou.datenow()
  },
  methods: {
    moment,
    inputChange(e) {
      // console.log('e', e)
    },
    async submit() {
      this.loading = true
      var fd = this.input
      var res = false
      if (this.action === 'Create') {
        res = await lou.customUrlPost2('instansi', fd, true, false)
      } else {
        fd.id = this.editdata.id
        fd.bpr_id = this.editdata.bpr_id
        res = await lou.customUrlPut2('instansi', fd, true, false)
      }
      if (res) {
        this.loading = false
        this.$parent.getData()
        this.handleCancel()
      }
    },
    async showModal(action, data) {
      // var res = await lou.customUrlGet('permission', false, true)
      // var res1 = await lou.customUrlGet('roles', false, true)
      // var res2 = await lou.customUrlGet('roletype', false, true)
      // if (res) {
      //   this.datapermissions = res.data
      // }
      // if (res1) {
      //   this.dataroles = res1.data
      // }
      // if (res2) {
      //   this.dataroletype = res2.data
      // }
      this.editdata = this.$g.clone(data)
      if (action !== 'Create') {
        // console.log("moment().add(4, 'hours').format('HH:mm')", moment().add(4, 'hours').format('HH:mm'))
        // console.log('this.editdata', this.editdata)
        this.input = this.editdata
        this.editshiftmode = false
      } else {
        this.resetForm()
      }
      this.visible = true
      this.action = action
    },
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    resetForm() {
      this.action = 'Create'
      this.editdata = []
      this.input = {
        keterangan: '',
      }

      this.datatable = []
      this.editshiftmode = false
      this.visible = false
      this.loading = false
      this.datenow = ''
    },
    onSearch(searchText) {
      this.dataSource = !searchText ? [] : ['Pagi', 'Sore', 'Damn']
    },
    handleChange(value) {
      // console.log(`selected ${value}`)
    },
    handleBlur() {
      // console.log('blur')
    },
    handleFocus() {
      // console.log('focus')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    capt(word) {
      return lou.capitalize(word)
    },
  },
}
</script>

<style></style>
